<script lang="ts" setup>
import { LocalStorageKey } from '@/modules/core/enums'

const { location: locationUrl } = useRoute().params
useLocationParam().value = locationUrl as string

const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')

const showConfirmationModal = ref<boolean>(false)
const { data: branches } = useAsyncData('pending-schedules', async () => {
  if (!useCustomer().customer.value || !useCustomer().customer.value.id)
    return null

  const fetched = await $fetch<any>('/api/procedure/schedule', {
    query: {
      userId: useCustomer().customer.value.id,
    },
    headers: {
      AuthToken: accessToken.value,
    },
  })

  showConfirmationModal.value = !!(fetched && Object.keys(fetched).length)

  return fetched
}, {
  watch: [() => useRoute().name, accessToken],
  server: false,
})

const { setStep } = useCart()

onNuxtReady(() => {
  setStep(0)
})
</script>

<template>
  <div class="h-svh flex flex-col">
    <THeader />

    <main class="flex-1">
      <slot />

      <Dialog
        :show="showConfirmationModal"
        @close="showConfirmationModal = false"
      >
        <ProfileProcedureSchedulingConfirmationModal
          v-if="showConfirmationModal"
          :branches="branches"
          :show="showConfirmationModal"
          @close="showConfirmationModal = false"
        />
      </Dialog>
    </main>

    <TFooter />
  </div>
</template>
